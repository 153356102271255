; (function ($) {
    $(function () {
        var items = localStorage.gagaoptcart ? JSON.parse(localStorage.gagaoptcart) : {};
        
        var vHeader = new Vue({
            el: '.i-header',
            data: {
                items: items,
                count: Object.keys(items).length,
            },
            methods: {
                clearData() {
                    this.items = null;
                    this.count = 0;
                }
            }
        });
        var footCart = new Vue({
            el: '#footer-cart',
            data: {
                items: items,
                count: Object.keys(items).length,
            },
            methods: {
                clearData() {
                    this.items = null;
                    this.count = 0;
                }
            }
        });
        
        var vCart = new Vue({
            el: '.mml-ttacncart',
            data: {
                items: items,
                count: Object.keys(items).length,
                txt: ''
            },
            watch: {
            },
            mounted: function () {
               if (this.count != 0) {
                   $('.one').addClass('show');
                   $('.zero').removeClass('show');
               } else {
                    $('.one').removeClass('show');
                    $('.zero').addClass('show');
               }
            },
            updated: function () {
               if (this.count != 0) {
                   $('.one').addClass('show');
                   $('.zero').removeClass('show');
               } else {
                    $('.one').removeClass('show');
                    $('.zero').addClass('show');
               }
            },
            methods: {
                clearData () {
                    this.items = null;
                    this.count = 0;
                    this.txt = '';
                },
                add: function (e, el) {
                    var btn = el ? el : e.currentTarget;
                    var ds = btn.dataset;
                    var data = {
                        image: ds.image,
                        name: ds.name,
                        sku: ds.sku,
                        txt: ''
                    }
                    if (items[ds.id]) {
                        showTip('Item Already Add to Your  Cart.');
                    } else {
                        Vue.set(vCart.items, ds.id, data);
                        items[ds.id] = data;
                        vHeader.count = Object.keys(items).length;
                        footCart.count = Object.keys(items).length;
                        vCart.count = Object.keys(items).length;
                        vCart.txt = data.txt != '' ? data.txt : '';
                        saveCart();
                        showTip('Item Successfully Added to Your Cart.');
                    }
                    
                },
                remove: function (id) {
                    Vue.set(vCart.items, id, null);
                    delete items[id];
                    vHeader.count = Object.keys(items).length;
                    footCart.count = Object.keys(items).length;
                    vCart.count = Object.keys(items).length;
                    saveCart();
                    save();
                },
                changeTxt: function ( item ) {
                    saveCart();
                    save();
                },
                showTip: showTip
            }
        });
        function saveCart() {
            var LS = window.localStorage;
            var data = JSON.stringify(items);            
            LS.gagaoptcart = data;
        }
        function showTip(message) {
            var tip = document.getElementById('tips-text');
            tip.innerHTML = '<p>' + message + '</p>';
            var timer = setTimeout(() => {
                $(tip).parent().removeClass('mml-show');
            }, (1500));
            $(tip).parent().addClass('mml-show');
        }
    
        if(typeof(Storage) !== "undefined")
        {
            save();
        } else {
            console.log('抱歉! 不支持 web 存储。');
        }
        function save() {
            var shopData  = $('.shopData')[0];
            var item = localStorage.getItem("gagaoptcart");
            
            var shopHtml = '';
            var tableHtml = '';
            if (item && shopData) {
                item = JSON.parse(item);
                $.each(item, function (index, value) {
                    shopHtml += `<tr>
                                    <td>${value.name}</td>
                                    <td>${value.sku}</td>
                                    <td>${value.txt}</td>
                                </tr>
                    `;
                });     
                shopData.value = shopHtml;           
            } 
        }

        $(document).on('click', '.fa-cart-plus', function (event) {
            vCart.add(event, this);
            return false;
        });
        document.addEventListener('wpcf7mailsent', function (event) {
            if ($('.shopData').val()) {
                $('.one').removeClass('show');
                $('.zero').addClass('show');
                localStorage.removeItem('gagaoptcart');
                vHeader.clearData();
                vCart.clearData();
                footCart.clearData();
            }
        }, false);

    });
})(jQuery);

